import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import FooterInfoItem from "./FooterInfoItem";

const FooterInfo: React.FC<Footer.IFooterInfoProps> = ({
  termsOfServiceLink,
}) => (
  <Box
    width="100%"
    display="flex"
    justifyContent="center"
    columnGap={2.5}
    pt={8}
    pb={2}
  >
    <Typography variant="body2" color="textSecondary">
      &copy; {new Date().getFullYear()} AI Planet
    </Typography>
    <>
      <Divider flexItem orientation="vertical" />
      <FooterInfoItem label="Terms" route={termsOfServiceLink} />
    </>
    <Divider flexItem orientation="vertical" />
    <FooterInfoItem label="Privacy" route="/privacy-policy" />
  </Box>
);

export default FooterInfo;
