import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MuiLink from "@mui/material/Link";

const FooterSection: React.FC<Footer.IFooterSectionProps> = ({
  footerData,
  label,
}) => {
  return (
    <>
      <Box px={4}>
        <Typography variant="h6">{label}</Typography>
      </Box>
      <List>
        {footerData.map(({ id, route, label, isNewTab }) => (
          <ListItem key={id}>
            <MuiLink
              href={route}
              target={isNewTab ? "_blank" : ""}
              underline="none"
            >
              <ListItemText>{label}</ListItemText>
            </MuiLink>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default FooterSection;
