import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Logo from "../common/Logo";
import FooterSection from "./FooterSection";
import FooterSocialMediaSection from "./FooterSocialMediaSection";
import FooterInfo from "./FooterInfo";
import {
  moreSectionData,
  organizationSectionData,
  gettingStartedSectionData,
  learnSectionData,
} from "./utils/footerData";
import Link from "next/link";
import { FRONTEND_COMMUNITY_URL } from "../../../utils/constants";

const Footer = () => {
  return (
    <footer>
      <Box
        position="relative"
        boxShadow="rgb(0 0 0 / 15%) 0px -1.5px 3px"
        borderTop="1px solid rgb(0 0 0 / 0%)"
        pt={12}
      >
        <Container maxWidth="xl">
          <Box position="absolute">
            <Grid container>
              <Grid item xs={12} xl={2}>
                <Box px={4} mb={4}>
                  <Link href={FRONTEND_COMMUNITY_URL}>
                    <a>
                      <Logo />
                    </a>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.5}>
                <FooterSection label="Learn" footerData={learnSectionData} />
              </Grid>
              <Grid item xs={12} md={4} sm={6} lg={3} xl={2.5}>
                <FooterSection
                  label="Getting Started"
                  footerData={gettingStartedSectionData}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.5}>
                <FooterSection label="More" footerData={moreSectionData} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.5}>
                <FooterSection
                  label="Organization"
                  footerData={organizationSectionData}
                />
                <Box mt={4}>
                  <FooterSocialMediaSection />
                </Box>
              </Grid>
            </Grid>
            <FooterInfo termsOfServiceLink="/terms-of-service" />
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
