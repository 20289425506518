import React from "react";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";

const FooterInfoItem: React.FC<Footer.IFooterInfoItemProps> = ({
  label,
  route,
}) => (
  <MuiLink href={route} underline="none">
    <Typography variant="body2" color="textSecondary">
      {label}
    </Typography>
  </MuiLink>
);

export default FooterInfoItem;
