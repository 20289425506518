import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import FooterIcon from "./FooterIcon";
import { socialMediaLinks } from "./utils/footerData";

const FooterSocialMediaSection = () => (
  <>
    <Box px={4}>
      <Typography variant="h6">Social Media</Typography>
    </Box>
    <List>
      <ListItem>
        {socialMediaLinks.map(({ id, route, icon }) => (
          <FooterIcon key={id} route={route} Icon={icon} />
        ))}
      </ListItem>
    </List>
  </>
);

export default FooterSocialMediaSection;
