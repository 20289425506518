export const LinkedInSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM10 16H8V10H10V16ZM9 9.109C8.393 9.109 7.9 8.613 7.9 8C7.9 7.388 8.392 6.891 9 6.891C9.608 6.891 10.1 7.388 10.1 8C10.1 8.613 9.607 9.109 9 9.109ZM17 16H15.002V13.139C15.002 11.258 13 11.417 13 13.139V16H11V10H13V11.093C13.872 9.477 17 9.357 17 12.641V16Z"
      fill="#444444"
    />
  </svg>
);

export const FacebookSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM15 8H13.65C13.112 8 13 8.221 13 8.778V10H15L14.791 12H13V19H10V12H8V10H10V7.692C10 5.923 10.931 5 13.029 5H15V8Z"
      fill="#444444"
    />
  </svg>
);

export const InstagramSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.829 6.302C14.091 6.268 13.869 6.262 12 6.262C10.131 6.262 9.91 6.269 9.172 6.302C7.273 6.389 6.389 7.288 6.302 9.172C6.269 9.91 6.261 10.131 6.261 12C6.261 13.869 6.269 14.09 6.302 14.829C6.389 16.708 7.269 17.612 9.172 17.699C9.909 17.732 10.131 17.74 12 17.74C13.87 17.74 14.091 17.733 14.829 17.699C16.728 17.613 17.611 16.711 17.699 14.829C17.732 14.091 17.739 13.869 17.739 12C17.739 10.131 17.732 9.91 17.699 9.172C17.611 7.289 16.726 6.389 14.829 6.302ZM12 15.595C10.015 15.595 8.405 13.986 8.405 12C8.405 10.015 10.015 8.406 12 8.406C13.985 8.406 15.595 10.015 15.595 12C15.595 13.985 13.985 15.595 12 15.595ZM15.737 9.104C15.273 9.104 14.897 8.728 14.897 8.264C14.897 7.8 15.273 7.424 15.737 7.424C16.201 7.424 16.577 7.8 16.577 8.264C16.577 8.727 16.201 9.104 15.737 9.104ZM14.333 12C14.333 13.289 13.288 14.333 12 14.333C10.712 14.333 9.667 13.289 9.667 12C9.667 10.711 10.712 9.667 12 9.667C13.288 9.667 14.333 10.711 14.333 12ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.958 14.886C18.843 17.431 17.426 18.841 14.887 18.958C14.14 18.992 13.901 19 12 19C10.099 19 9.861 18.992 9.114 18.958C6.57 18.841 5.159 17.429 5.042 14.886C5.008 14.14 5 13.901 5 12C5 10.099 5.008 9.861 5.042 9.114C5.159 6.57 6.571 5.159 9.114 5.043C9.861 5.008 10.099 5 12 5C13.901 5 14.14 5.008 14.887 5.043C17.432 5.16 18.844 6.575 18.958 9.114C18.992 9.861 19 10.099 19 12C19 13.901 18.992 14.14 18.958 14.886Z"
      fill="#444444"
    />
  </svg>
);

export const YoutubeSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M19.615 3.18388C16.011 2.93788 7.984 2.93888 4.385 3.18388C0.488 3.44988 0.029 5.80388 0 11.9999C0.029 18.1849 0.484 20.5489 4.385 20.8159C7.985 21.0609 16.011 21.0619 19.615 20.8159C23.512 20.5499 23.971 18.1959 24 11.9999C23.971 5.81488 23.516 3.45088 19.615 3.18388ZM9 15.9999V7.99988L17 11.9929L9 15.9999Z"
        fill="#444444"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TwitterSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.25 5.13281C22.406 5.49954 21.513 5.74115 20.5992 5.85C21.5595 5.28768 22.2817 4.39433 22.6303 3.3375C21.7224 3.8684 20.7307 4.24091 19.6978 4.43906C19.2629 3.98322 18.7397 3.62058 18.1603 3.37319C17.5808 3.12581 16.9571 2.99884 16.327 3C13.7761 3 11.7117 5.03437 11.7117 7.54219C11.7099 7.89101 11.7499 8.2388 11.8308 8.57812C10.0016 8.49237 8.2104 8.02574 6.57187 7.20809C4.93333 6.39043 3.48351 5.23976 2.31516 3.82969C1.90527 4.52068 1.6885 5.30908 1.6875 6.1125C1.6875 7.6875 2.50922 9.07969 3.75 9.89531C3.01487 9.87787 2.29481 9.6833 1.65094 9.32812V9.38437C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9432 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3332 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07187C20.9616 7.875 20.9564 7.67812 20.947 7.48594C21.8485 6.84472 22.6283 6.04786 23.25 5.13281Z"
      fill="#444444"
    />
  </svg>
);
