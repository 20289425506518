import React from "react";
import MuiLink from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";

const FooterIcon: React.FC<Footer.IFooterIconProps> = ({ Icon, route }) => (
  <ListItemIcon sx={{ minWidth: "44px" }}>
    <MuiLink href={route} underline="none" target="_blank">
      <Icon />
    </MuiLink>
  </ListItemIcon>
);

export default FooterIcon;
