import {
  FRONTEND_BOOTCAMPS_URL,
  FRONTEND_CHALLENGES_URL,
  FRONTEND_COURSES_URL,
  FRONTEND_DISCUSS_URL,
  FRONTEND_LIVE_SESSIONS_URL,
  FRONTEND_NOTEBOOKS_URL,
  FRONTEND_WALL_OF_LOVE_URL,
} from "../../../../utils/constants";
import {
  FacebookSvg,
  InstagramSvg,
  LinkedInSvg,
  TwitterSvg,
  YoutubeSvg,
} from "./icons";

export const learnSectionData = [
  {
    id: 1,
    label: "Data Science & AI Courses",
    route: FRONTEND_COURSES_URL,
    isNewTab: false,
  },
  {
    id: 2,
    label: "Practice Data Science & AI Challenges",
    route: FRONTEND_CHALLENGES_URL,
    isNewTab: false,
  },
  {
    id: 3,
    label: "Discussion forum",
    route: FRONTEND_DISCUSS_URL,
    isNewTab: true,
  },
  {
    id: 4,
    label: "Data Science & AI Live Sessions",
    route: FRONTEND_LIVE_SESSIONS_URL,
    isNewTab: false,
  },
  // {
  //   id: 5,
  //   label: "Community Notebooks (Code Share)",
  //   route: FRONTEND_NOTEBOOKS_URL,

  //   isNewTab: false,
  // },
  {
    id: 6,
    label: "Community Bootcamps",
    route: FRONTEND_BOOTCAMPS_URL,
    isNewTab: false,
  },
];

export const gettingStartedSectionData = [
  {
    id: 1,
    label: "Data Science",
    route: "/courses/5-week_Data_Science_Bootcamp",

    isNewTab: false,
  },
  {
    id: 2,
    label: "Machine Learning",
    route: "/courses/machine-learning-bootcamp",

    isNewTab: false,
  },
  {
    id: 3,
    label: "Deep Learning",
    route: "/courses/getting-started-with-deep-learning",

    isNewTab: false,
  },
  {
    id: 4,
    label: "Data Visualization 101",
    route: "/courses/data-visualization-101",

    isNewTab: false,
  },
  {
    id: 5,
    label: "Natural Language Processing 101",
    route: "/courses/getting-started-with-natural-language-processing",

    isNewTab: false,
  },
  {
    id: 6,
    label: "Time Series 101",
    route: "/courses/time-series-101",

    isNewTab: false,
  },
];

export const moreSectionData = [
  {
    id: 1,
    label: "Host AI & Machine Learning Challenges",
    route: "https://aiplanet.com/blog/ai-challenges",
    isNewTab: true,
  },
  {
    id: 2,
    label: "Blog",
    route: "https://aiplanet.com/blog",
    isNewTab: true,
  },
  {
    id: 3,
    label: "Learner Stories",
    route: FRONTEND_WALL_OF_LOVE_URL,
    isNewTab: false,
  },
];

export const organizationSectionData = [
  {
    id: 1,
    label: "About Us",
    route: "/about-us",
    isNewTab: false,
  },
  {
    id: 2,
    label: "Contact Us",
    route: "mailto:support@dphi.tech",
    isNewTab: true,
  },
];

export const socialMediaLinks = [
  {
    id: 1,
    icon: LinkedInSvg,
    route: "https://www.linkedin.com/company/aiplanet/",
  },
  {
    id: 2,
    icon: InstagramSvg,
    route: "https://www.instagram.com/aiplanethub/",
  },
  {
    id: 3,
    icon: FacebookSvg,
    route: "https://www.facebook.com/aiplanet/",
  },
  {
    id: 4,
    icon: TwitterSvg,
    route: "https://twitter.com/aiplanethub/",
  },
  {
    id: 5,
    icon: YoutubeSvg,
    route: "https://www.youtube.com/c/dphiofficial/featured",
  },
];
